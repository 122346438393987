import { makeStyles } from "@placehires/react-component-library";
import React from "react";
import SEO from "../layout/SEO";

const SocialPage: React.FC = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <SEO title="Social" />
      <iframe
        id="js-campsite-iframe"
        src="https://campsite.bio/matchii.io?iframe=1"
        frameBorder="0"
        scrolling="no"
        width="100%"
      />
      <script src="https://cdn.campsite.bio/js/iframe.js" async />
    </div>
  );
};

const useStyles = makeStyles()(() => ({
  container: {
    flex: 1,
    marginTop: 30,
    "& iframe": {
      height: "100%"
    }
  }
}));

export default SocialPage;
